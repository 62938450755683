import React from 'react'
import {Link} from 'gatsby'
import ReactWOW from 'react-wow'
import banner from '../../assets/images/home/main-screen.png'
import Bubbles from './Bubbles';
import logoAtlasian from '../../assets/images/logos/logo-atlassian.png'



const MainBanner = () => {
    return (
        <div className="hero-banner">
            <div className="container">
                <Bubbles />
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div id="stingray" />
                        <div className="banner-wrapper-content">
                            <span className="sub-title" style={{fontSize: 18}}>Security &amp; Compliance Managment</span>
                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <h1>ekko AGILITY</h1>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInLeft'>
                                <p>Manage your organizations security program, ISO27001, SOC 2 and other compliance goals on Atlassian with the ekko Agility solution.</p>
                            </ReactWOW>

                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <Link to="/contact" className="default-btn">
                                        Request a Demo
                                        <i className="flaticon-right"></i> 
                                        <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="banner-wrapper-image">
                                <img src={banner} alt="banner" />
                                <div className="hero-caption">Built on Atlassian Cloud </div>
                                <img src={logoAtlasian} alt="Logo Atlassian" className="logo-atlassian"/>
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainBanner
