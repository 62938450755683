import React from 'react'
import ReactWOW from 'react-wow'
import screen from '../../assets/images/home/integrations-graphic.png'


const IntegrationTemplates = () => {
    return (
        <>
          <section className="overview-area pb-100 pt-70">
            <div className="container">
                {/* Left Image Style */}
                <div className="overview-box">
              
                        <div className="overview-image">
                         <div className="overview-image-grid"></div>
                            <ReactWOW delay='.5s' animation='fadeInLeft'>
                                <div className="image">
                                    <img src={screen} alt="features" />
                                </div>
                            </ReactWOW>
                    </div>
                    
                    <div className="overview-content">
                        <div className="content right-content">
                            <span className="sub-title">Agility</span>
                            <h2>Integrations &amp; Templates</h2>
                            <h5 className="color-1C47A4">Utilize your favorite tools and apps using native Atlassian integrations.</h5>
                               <p className="pb-0">Agility also contains pre-defined policies, procedures and Jira templates including support for:</p>


                                <div className="features-list flex-layout pb-5 ml-0">
                                    <span className="tag"><span>ISO</span>27001</span>
                                    <span className="tag"><span>ISO</span>27001</span>
                                    <span className="tag">SSAE18 SOC 2</span>
                                    <span className="tag">HIPAA</span>
                                    <span className="tag">ISCyber Essentials+O27001</span>
                                    <span className="tag">GDPR / CCPA</span>
                                    <span className="tag">NIST Cyber Security Framework (CSF)</span>
                                    <span className="tag">Fedramp</span>
                                    <span className="tag">Cyber Security Maturity Model (CMMC)</span>
                                    <span className="tag">HITRUST</span>
                                  
                                </div>
                                <ReactWOW delay='.1s' animation='fadeInRight'>
                                    <a className="read-more-btn" href="/contact">Request Demo <i className="flaticon-right"></i></a>
                                </ReactWOW>
                            
                        </div>
                    </div>
                </div>
                {/* End Left Image Style */}

               
            </div>
        </section>
    
        </>
    )
}

export default IntegrationTemplates